var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "badge text-capitalize",
    class: _vm.readTypeClass
  }, [_vm._v(_vm._s(_vm.parsedReadType))]);

}
var staticRenderFns = []

export { render, staticRenderFns }