var render = function render(){
  var _vm$modals$remove$val;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-file-invoice",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }],
          staticClass: "form-control form-control-search input-sm",
          style: {
            width: '500px'
          },
          attrs: {
            "placeholder": "Search invoices..."
          },
          domProps: {
            "value": _vm.search
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) return;
              _vm.search = $event.target.value;
            }
          }
        }), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
          staticClass: "btn btn-primary ml-3",
          attrs: {
            "data-cy": "add-new-btn"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              _vm.showInvoiceForm = !_vm.showInvoiceForm;
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-fw fa-plus"
        }), _vm._v(" New Invoice ")]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [[_vm._v("Invoices")]], 2), _vm.loadingAction.accountInvoices ? _c('Spinner', {
    staticClass: "mb-5"
  }, [_vm._v("Loading invoices...")]) : _vm._e(), _vm.showInvoiceForm ? _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "block-content bg-body-light"
  }, [_c('InvoiceCreateForm', {
    attrs: {
      "account": _vm.selectedAccount
    },
    on: {
      "completed": _vm.refreshInvoices
    }
  })], 1)]) : _vm._e(), !_vm.loadingAction.accountInvoices && _vm.sortedInvoices.length > 0 ? _c('div', {
    attrs: {
      "id": "accordion",
      "role": "tablist"
    }
  }, _vm._l(_vm.sortedInvoices, function (invoice) {
    return _c('div', {
      key: invoice._id,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header block-header-default",
      class: {
        'is-simulated': invoice.simulated
      },
      attrs: {
        "id": `accordion_h${invoice._id}`,
        "role": "tab"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('a', {
      staticClass: "font-w600 collapsed",
      attrs: {
        "data-toggle": "collapse",
        "data-parent": "#accordion",
        "href": `#accordion_a${invoice._id}`
      },
      on: {
        "click": function ($event) {
          _vm.edit === invoice._id ? _vm.edit = false : _vm.edit = invoice._id;
        }
      }
    }, [invoice.values ? _c('span', [_c('div', {
      staticClass: "mb-1"
    }, [_vm._v(_vm._s(_vm._f("date")(invoice.values.startDate)) + " - " + _vm._s(_vm._f("date")(invoice.values.endDate)))]), _c('div', {
      staticClass: "small"
    }, [_c('span', {
      staticClass: "mr-4"
    }, [_c('i', {
      staticClass: "fa fa-meter fa-fw mr-1"
    }), _vm._v(_vm._s(_vm._f("numberFormat")(_vm.selectedAccount.type !== 'water' ? invoice.values.totalUnits : invoice.values.totalWaterVolume, 2)))]), !_vm.$auth.settings.hideCostData ? _c('span', {
      staticClass: "mr-4"
    }, [_c('i', {
      staticClass: "fa fa-file-invoice-dollar fa-fw mr-1"
    }), _vm._v(" £" + _vm._s(_vm._f("numberFormat")(invoice.values.netTotalCost, 2)))]) : _vm._e(), !isNaN(invoice.values.netTotalCost) && !isNaN(invoice.values[_vm.selectedAccount.type !== 'water' ? 'totalUnits' : 'totalWaterVolume']) && !_vm.$auth.settings.hideCostData ? _c('span', {
      staticClass: "mr-4"
    }, [_c('i', {
      staticClass: "fa fa-coin fa-fw mr-1"
    }), _vm._v(" " + _vm._s(_vm._f("numberFormat")(invoice.values[_vm.selectedAccount.type !== 'water' ? 'totalUnits' : 'totalWaterVolume'] === 0 ? 0 : invoice.values.netTotalCost * 100 / invoice.values[_vm.selectedAccount.type !== 'water' ? 'totalUnits' : 'totalWaterVolume'], 2)) + "p / unit")]) : _vm._e(), _c('ReadType', {
      attrs: {
        "invoice": invoice
      }
    })], 1)]) : _c('span', {
      staticClass: "text-danger"
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v(" Invoice has no values")])])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.loading.paid[invoice._id] ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-2"
    })]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('div', {
      staticClass: "custom-control custom-checkbox custom-control-inline",
      class: {
        'custom-control-success': invoice.isPaid
      }
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": `invoice-paid-${invoice._id}`,
        "type": "checkbox",
        "disabled": _vm.loading.paid[invoice._id],
        "name": `invoice-paid-${invoice._id}`
      },
      domProps: {
        "checked": invoice.isPaid
      },
      on: {
        "input": function (e) {
          return _vm.onClickPaid(invoice, e.target.checked);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      class: {
        'text-success': invoice.isPaid
      },
      attrs: {
        "for": `invoice-paid-${invoice._id}`
      }
    }, [_vm._v("Paid")])]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          _vm.modals.creditNote = invoice;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-coins"
    }), _vm._v(" Credit Note ")]) : _vm._e(), _c('button', {
      staticClass: "btn btn-primary-inline",
      on: {
        "click": function ($event) {
          _vm.modals.move = invoice;
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-up"
    }), _vm._v(" Move")]), invoice.s3Key ? _c('button', {
      staticClass: "btn font-w600",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickDownloadInvoice(invoice);
          }.apply(null, arguments);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-file-pdf"
    }), _vm._v(" " + _vm._s(_vm.invoiceLoadingAction.downloadInvoice === invoice._id ? 'Loading...' : 'PDF') + " ")]) : _c('button', {
      staticClass: "btn font-w600",
      attrs: {
        "disabled": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-file-pdf"
    }), _vm._v(" PDF")]), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn text-danger",
      attrs: {
        "disabled": _vm.loadingAction.deleteAccountInvoice
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickDeleteInvoice(invoice);
          }.apply(null, arguments);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.deleteAccountInvoice === invoice._id ? 'Deleting...' : 'Delete') + " ")]) : _vm._e()])]), _vm.edit === invoice._id ? _c('div', {
      staticClass: "collapse",
      attrs: {
        "id": `accordion_a${invoice._id}`,
        "role": "tabpanel",
        "data-parent": "#accordion"
      }
    }, [_c('div', {
      staticClass: "block-content"
    }, [_c('InvoiceResults', {
      key: invoice._id,
      attrs: {
        "loading": _vm.loadingAction.accountInvoices,
        "invoice": invoice
      },
      on: {
        "change": _vm.onInvoiceChange
      }
    })], 1)]) : _vm._e()]);
  }), 0) : !_vm.loadingAction.accountInvoices ? _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.accountInvoices.length > 0 ? 'No invoices found.' : 'No invoices uploaded.') + " ")])]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) && _vm.deletedInvoices.length > 0 ? _c('div', [_vm._m(0), _c('p', [_vm._v("These invoices have been deleted in the past. You can restore them by clicking on the Restore button.")]), !_vm.showDeleted ? _c('a', {
    staticClass: "font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showDeleted = true;
      }
    }
  }, [_c('div', {
    staticClass: "block block-rounded bg-light text-center p-3"
  }, [_vm._v("Show deleted invoices")])]) : _vm._e(), _vm.showDeleted ? _c('div', _vm._l(_vm.deletedInvoices, function (invoice) {
    return _c('div', {
      key: invoice._id,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header block-header-default",
      attrs: {
        "id": `accordion_h${invoice._id}`,
        "role": "tab"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('a', {
      staticClass: "font-w600 collapsed",
      attrs: {
        "data-toggle": "collapse",
        "data-parent": "#accordion",
        "href": `#accordion_a${invoice._id}`
      }
    }, [invoice.values ? _c('span', [_c('span', {
      staticClass: "mr-4"
    }, [_vm._v(_vm._s(_vm._f("date")(invoice.values.startDate)) + " - " + _vm._s(_vm._f("date")(invoice.values.endDate)))]), 'totalUnits' in invoice.values && _vm.selectedAccount.type !== 'water' ? _c('span', {
      staticClass: "mr-4"
    }, [_vm._v(_vm._s(invoice.values.totalUnits))]) : _vm._e(), 'totalWaterVolume' in invoice.values && _vm.selectedAccount.type === 'water' ? _c('span', {
      staticClass: "mr-4"
    }, [_vm._v(_vm._s(invoice.values.totalWaterVolume))]) : _vm._e(), !_vm.$auth.settings.hideCostData ? _c('span', {
      staticClass: "mr-4"
    }, [_vm._v("£" + _vm._s(_vm._f("round")(invoice.values.netTotalCost, 2)))]) : _vm._e(), _c('ReadType', {
      attrs: {
        "invoice": invoice
      }
    }), _c('span', {
      staticClass: "badge badge-danger ml-2"
    }, [_vm._v("DELETED")])], 1) : _c('span', {
      staticClass: "text-danger"
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v(" Invoice has no values")])])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn text-warning",
      attrs: {
        "disabled": _vm.loadingAction.restoreInvoice
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickRestoreInvoice(invoice);
          }.apply(null, arguments);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-arrow-rotate-left"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.restoreInvoice ? 'Deleting...' : 'Restore') + " ")]) : _vm._e()])]), _c('div', {
      staticClass: "collapse",
      attrs: {
        "id": `accordion_a${invoice._id}`,
        "role": "tabpanel",
        "data-parent": "#accordion"
      }
    }, [_c('div', {
      staticClass: "block-content"
    }, [_c('InvoiceResults', {
      key: invoice._id,
      attrs: {
        "loading": _vm.loadingAction.accountInvoices,
        "invoice": invoice
      },
      on: {
        "change": _vm.onInvoiceChange
      }
    })], 1)])]);
  }), 0) : _vm._e()]) : _vm._e(), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.pdf,
      "title": "PDF Download"
    },
    on: {
      "close": function ($event) {
        _vm.modals.pdf = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        var _vm$modals$pdf, _vm$modals$pdf$downlo, _vm$modals$pdf2, _vm$modals$pdf2$downl;
        return [_c('button', {
          staticClass: "btn btn-sm btn-light",
          attrs: {
            "type": "button",
            "disabled": _vm.loading.main
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              _vm.modals.pdf = false;
            }
          }
        }, [_vm._v("Close")]), _c('button', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "type": "button",
            "data-bs-dismiss": "modal",
            "disabled": !((_vm$modals$pdf = _vm.modals.pdf) !== null && _vm$modals$pdf !== void 0 && (_vm$modals$pdf$downlo = _vm$modals$pdf.downloadUrls) !== null && _vm$modals$pdf$downlo !== void 0 && _vm$modals$pdf$downlo.validationSheetUrl)
          },
          on: {
            "click": function ($event) {
              return _vm.downloadInvoice('validationSheetUrl');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-file-invoice fa-fw"
        }), _vm._v(" Validation Sheet ")]), _c('button', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "type": "button",
            "data-bs-dismiss": "modal",
            "disabled": !((_vm$modals$pdf2 = _vm.modals.pdf) !== null && _vm$modals$pdf2 !== void 0 && (_vm$modals$pdf2$downl = _vm$modals$pdf2.downloadUrls) !== null && _vm$modals$pdf2$downl !== void 0 && _vm$modals$pdf2$downl.stampedUrl)
          },
          on: {
            "click": function ($event) {
              return _vm.downloadInvoice('stampedUrl');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-stamp fa-fw"
        }), _vm._v(" Stamped ")]), _c('button', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "type": "button",
            "data-bs-dismiss": "modal"
          },
          on: {
            "click": function ($event) {
              return _vm.downloadInvoice('url');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-file fa-fw"
        }), _vm._v(" Original ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex mb-3"
  }, [_vm._v("Choose which PDF you would like to download")])]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Invoice",
      "text": _vm.modals.remove ? `Please confirm you would like to remove invoice number: <strong>${(_vm$modals$remove$val = _vm.modals.remove.values) === null || _vm$modals$remove$val === void 0 ? void 0 : _vm$modals$remove$val.invoiceNumber}</strong>` : null
    },
    on: {
      "close": function ($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onDeleteInvoice
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.creditNote,
      "title": "Move Invoice to Credit Note",
      "prevent": "",
      "loading": _vm.accountLoadingAction.convertInvoiceToCreditNote
    },
    on: {
      "close": function ($event) {
        _vm.modals.creditNote = false;
      },
      "submit": _vm.onClickMoveToCreditNote
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.move,
      "title": "Move Invoice",
      "prevent": ""
    },
    on: {
      "close": function ($event) {
        _vm.modals.move = false;
      },
      "submit": _vm.onMove
    }
  }, [!_vm.loading.main ? _c('LookaheadSearch', {
    key: _vm.modals.move,
    ref: "lookaheadComponent",
    attrs: {
      "action": function (query, params) {
        return _vm.onSearchAccounts(query, params);
      },
      "results": _vm.accountResults,
      "loading": _vm.accountLoadingAction.search,
      "title": 'Account'
    },
    on: {
      "select": function (account) {
        return _vm.onSelectAccount(account);
      }
    },
    scopedSlots: _vm._u([{
      key: "result",
      fn: function (slotProps) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "mr-2"
        }, [_c('strong', [_vm._v(_vm._s(slotProps.data.name))])]), _c('span', {
          staticClass: "badge badge-info text-capitalize mr-2"
        }, [_vm._v(" " + _vm._s(slotProps.data.type) + " ")]), slotProps.data.supplier ? _c('span', {
          staticClass: "badge badge-info text-capitalize mr-2"
        }, [_vm._v(" " + _vm._s(slotProps.data.supplier.name) + " ")]) : _vm._e(), _c('div', {
          staticClass: "mr-2"
        }, [slotProps.data.asset ? _c('span', {
          staticClass: "badge badge-primary text-capitalize mr-2"
        }, [_vm._v(" " + _vm._s(slotProps.data.asset.siteName) + " ")]) : _vm._e()])])])];
      }
    }], null, false, 3555947826)
  }) : _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm.loading.main ? _c('Spinner', [_vm._v("Updating " + _vm._s(_vm.title) + "..")]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h4', {
    staticClass: "mb-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-ban text-danger"
  }), _vm._v(" Deleted Invoices")]);

}]

export { render, staticRenderFns }