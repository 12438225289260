var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-content"
  }, [_c('div', [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Add New Invoice")]), _c('p', [_vm._v(" Create a new invoice by filling out the form below. If you add any reads, these will also be visible in the Readings tab. The same for consumption, this can be viewed in the consumption tab. ")]), _c('div', [_c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "simulated",
      "type": "select",
      "label": "Simulated",
      "description": "If the data below is estimated, and not from an actual invoice, you can mark this as simulated so it can be viewed separately on reports.",
      "options": [{
        label: 'No',
        value: false
      }, {
        label: 'Yes',
        value: true
      }]
    },
    model: {
      value: _vm.form.simulated,
      callback: function ($$v) {
        _vm.$set(_vm.form, "simulated", $$v);
      },
      expression: "form.simulated"
    }
  }), _vm._l(_vm.invoiceValues, function (value) {
    return _c('div', {
      key: value.key
    }, [_c('FormGroup', {
      attrs: {
        "id": value.key,
        "label": value.friendly,
        "type": _vm.formTypeMap[value.valueType],
        "error": _vm.validationErrors[value.key]
      },
      on: {
        "input": function (input) {
          return _vm.onInputValue(input, value.key);
        }
      },
      model: {
        value: _vm.form.values[value.key],
        callback: function ($$v) {
          _vm.$set(_vm.form.values, value.key, $$v);
        },
        expression: "form.values[value.key]"
      }
    })], 1);
  }), _c('FormGroup', {
    attrs: {
      "id": "supplierId",
      "options": _vm.sortedSuppliers.map(function (s) {
        return {
          label: s.name,
          value: s._id
        };
      }),
      "label": "Supplier Name",
      "type": "select2"
    },
    model: {
      value: _vm.form.supplierId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "supplierId", $$v);
      },
      expression: "form.supplierId"
    }
  }), _c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Invoice PDF")]), _c('FileUpload', {
    on: {
      "reset": _vm.onClearFile
    },
    model: {
      value: _vm.form.file,
      callback: function ($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  })], 1)])], 2)])]), ['electricity', 'gas', 'water'].includes(_vm.account.type) ? _c('div', [_vm._m(0), _c('p', [_vm._v("All the rates related to this invoice. Changing the consumption or cost of a rate will automatically update the totals table below.")]), _vm.form.rates.length > 0 ? _c('table', {
    staticClass: "table mb-4"
  }, _vm._l(_vm.form.rates, function (rate, index) {
    return _c('tbody', {
      key: rate.id,
      attrs: {
        "id": `rate-${rate.id}`
      }
    }, [_c('tr', [_c('th', {
      staticClass: "bg-light",
      attrs: {
        "colspan": "4"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('span', {
      staticClass: "font-size-h5"
    }, [_vm._v(_vm._s(rate.rateName))]), _c('span', [['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) && rate.startRead === null ? _c('button', {
      staticClass: "btn btn-alt-primary btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickAddReads(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-tachometer-alt mr-1"
    }), _vm._v(" Add Reads ")]) : _vm._e(), rate.startRead !== null || rate.startRead !== null ? _c('button', {
      staticClass: "btn btn-alt-danger btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickRemoveReads(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-tachometer-alt mr-1"
    }), _vm._v(" Remove Reads ")]) : _vm._e(), rate.startDate === null && rate.startDate === null ? _c('button', {
      staticClass: "btn btn-alt-primary btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickAddDates(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar-alt mr-1"
    }), _vm._v(" Add Dates ")]) : _vm._e(), rate.startDate !== null || rate.startDate !== null ? _c('button', {
      staticClass: "btn btn-alt-danger btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickRemoveDates(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar-alt mr-1"
    }), _vm._v(" Remove Dates ")]) : _vm._e(), _c('button', {
      staticClass: "btn btn-alt-danger btn-sm",
      on: {
        "click": function ($event) {
          return _vm.onClickRemoveRate(rate, index);
        }
      }
    }, [_vm._m(1, true)])])])])]), _c('tr', [_vm._m(2, true), _c('td', [_vm._v(_vm._s(_vm.getFriendlyRateType(rate.type)))]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `consumption-${rate.id}`,
        "type": "select",
        "value": rate.type,
        "options": _vm.rateTypes
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'type');
        }
      }
    })], 1)]), rate.startDate !== null || rate.endDate !== null ? _c('tr', [_vm._m(3, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyStartDate)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedStartDate))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `startDate-${rate.id}`,
        "type": "datePicker",
        "value": rate.startDate
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'startDate');
        }
      }
    })], 1)]) : _vm._e(), rate.startDate !== null || rate.endDate !== null ? _c('tr', [_vm._m(4, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyEndDate)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedEndDate))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `endDate-${rate.id}`,
        "type": "datePicker",
        "value": rate.endDate
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'endDate');
        }
      }
    })], 1)]) : _vm._e(), rate.type !== 'tax' ? _c('tr', [_vm._m(5, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyConsumption)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedConsumption))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `consumption-${rate.id}`,
        "type": "number",
        "step": "0.01",
        "value": rate.consumption
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'consumption');
        }
      }
    })], 1)]) : _vm._e(), rate.unitRate !== null ? _c('tr', [_vm._m(6, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyUnitRate)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedUnitRate))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `unitRate-${rate.id}`,
        "type": "number",
        "step": "0.01",
        "value": rate.unitRate
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'unitRate');
        }
      }
    })], 1)]) : _vm._e(), _c('tr', [_vm._m(7, true), _c('td', [_vm._v(" " + _vm._s(rate.cost)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.calculatedCost))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `cost-${rate.id}`,
        "type": "number",
        "step": "0.01",
        "value": rate.cost
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'cost');
        }
      }
    })], 1)]), rate.startRead !== null || rate.endRead !== null ? _c('tr', [_c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_c('strong', [_vm._v("Start Read")])]), _c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_vm._v(" " + _vm._s(rate.friendlyStartRead) + " "), rate.friendlyStartReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyStartReadType) + ")")]) : _vm._e(), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(" " + _vm._s(rate.friendlyCalculatedStartRead) + " "), rate.friendlyCalculatedStartReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyCalculatedStartReadType) + ")")]) : _vm._e()])]), _c('td', [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      class: _vm.displayRegister ? 'col-md-6' : 'col-md-8'
    }, [_c('div', {
      staticClass: "form-group mb-0"
    }, [_c('label', [_vm._v(" Value ")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `startRead-${rate.id}`,
        "type": "number",
        "min": "0",
        "step": "0.01",
        "placeholder": "Value",
        "value": rate.startRead
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'startRead');
        }
      }
    }), _c('div', {
      staticClass: "input-group-append",
      class: _vm.displayRegister ? '' : 'w-25'
    }, [_c('FormItem', {
      attrs: {
        "id": `startReadUnits-${rate.id}`,
        "options": _vm.unitOptions,
        "type": "select",
        "is-alt": true
      },
      model: {
        value: rate.startReadUnit,
        callback: function ($$v) {
          _vm.$set(rate, "startReadUnit", $$v);
        },
        expression: "rate.startReadUnit"
      }
    })], 1)], 1)])]), _c('div', {
      class: _vm.displayRegister ? 'col-md-3' : 'col-md-4'
    }, [_c('label', [_vm._v(" Type ")]), _c('FormItem', {
      attrs: {
        "id": `startReadType-${rate.id}`,
        "type": "text",
        "placeholder": "Type",
        "value": rate.startReadType
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'startReadType');
        }
      }
    })], 1), _vm.displayRegister ? _c('div', {
      staticClass: "col"
    }, [_c('label', [_vm._v(" Register ")]), _c('FormItem', {
      attrs: {
        "id": `endReadRegister-${rate.id}`,
        "type": "select",
        "placeholder": "Register",
        "value": rate.endReadRegister,
        "options": _vm.filteredRegisterIds
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'endReadRegister');
        }
      }
    })], 1) : _vm._e()])]), _c('td')]) : _vm._e(), rate.startRead !== null || rate.endRead !== null ? _c('tr', [_c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_c('strong', [_vm._v("End Read")])]), _c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_vm._v(" " + _vm._s(rate.friendlyEndRead) + " "), rate.friendlyEndReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyEndReadType) + ")")]) : _vm._e(), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(" " + _vm._s(rate.friendlyCalculatedEndRead) + " "), rate.friendlyCalculatedEndReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyCalculatedEndReadType) + ")")]) : _vm._e()])]), _c('td', [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      class: _vm.displayRegister ? 'col-md-6' : 'col-md-8'
    }, [_c('label', [_vm._v(" Value ")]), _c('div', {
      staticClass: "form-group mb-0"
    }, [_c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `endRead-${rate.id}`,
        "type": "number",
        "min": "0",
        "step": "0.01",
        "placeholder": "Value",
        "value": rate.endRead
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'endRead');
        }
      }
    }), _c('div', {
      staticClass: "input-group-append",
      class: _vm.displayRegister ? '' : 'w-25'
    }, [_c('FormItem', {
      attrs: {
        "id": `endReadUnits-${rate.id}`,
        "options": _vm.unitOptions,
        "type": "select",
        "is-alt": true
      },
      model: {
        value: rate.endReadUnit,
        callback: function ($$v) {
          _vm.$set(rate, "endReadUnit", $$v);
        },
        expression: "rate.endReadUnit"
      }
    })], 1)], 1)])]), _c('div', {
      class: _vm.displayRegister ? 'col-md-3' : 'col-md-4'
    }, [_c('label', [_vm._v(" Type ")]), _c('FormItem', {
      staticClass: "mb-0",
      attrs: {
        "id": `endReadType-${rate.id}`,
        "type": "text",
        "placeholder": "Type",
        "value": rate.endReadType
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, index, 'endReadType');
        }
      }
    })], 1), _vm.displayRegister ? _c('div', {
      staticClass: "col-md-3"
    }, [_c('label', [_vm._v(" Register ")]), _c('FormItem', {
      attrs: {
        "id": `endReadRegister-${rate.id}`,
        "type": "select",
        "placeholder": "Register",
        "value": rate.endReadRegister,
        "options": _vm.filteredRegisterIds
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'endReadRegister');
        }
      }
    })], 1) : _vm._e()])])]) : _vm._e()]);
  }), 0) : _vm._e(), _c('button', {
    staticClass: "btn btn-alt-primary btn-block mb-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickAddRate.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("Add Rate")])]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Totals")]), _vm._m(8), _c('table', {
    staticClass: "table table-striped mb-4"
  }, [_vm._m(9), _c('tbody', _vm._l(_vm.invoiceTotals, function (value) {
    return _c('tr', {
      key: value.key
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(value.friendly))]), _c('td', {
      staticClass: "text-info font-w600"
    }, [_c('FormItem', {
      attrs: {
        "id": value.key,
        "type": value.formType,
        "options": [],
        "value": _vm.form.values[value.key]
      },
      on: {
        "input": function (input) {
          return _vm.onInputValue(input, value.key);
        }
      }
    })], 1)]);
  }), 0)])]) : _vm._e(), _vm.account.type === 'waste' ? _c('div', [_vm._m(10), _c('p', [_vm._v("Enter all the invoice consumption values.")]), _c('table', {
    staticClass: "table table-striped mb-4"
  }, [_vm._m(11), _c('tbody', _vm._l(_vm.wasteCategories.filter(function (c) {
    return _vm.wasteConsumption[c.name];
  }), function (category, idx) {
    return _c('tr', {
      key: category.name
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(category.name))]), _c('td', {
      staticClass: "text-info font-w600"
    }, [_c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      staticClass: "col-xl-3 mr-3",
      attrs: {
        "id": `consumption-value-${idx}`,
        "placeholder": " "
      },
      model: {
        value: _vm.wasteConsumption[category.name].value,
        callback: function ($$v) {
          _vm.$set(_vm.wasteConsumption[category.name], "value", $$v);
        },
        expression: "wasteConsumption[category.name].value"
      }
    }), _c('FormItem', {
      staticClass: "col-xl-3",
      attrs: {
        "id": `consumption-unit-${idx}`,
        "type": "select",
        "placeholder": `Select a unit`,
        "options": [{
          label: 'kg',
          value: 'kg'
        }, {
          label: 'tonnes',
          value: 'tonnes'
        }]
      },
      model: {
        value: _vm.wasteConsumption[category.name].unit,
        callback: function ($$v) {
          _vm.$set(_vm.wasteConsumption[category.name], "unit", $$v);
        },
        expression: "wasteConsumption[category.name].unit"
      }
    })], 1)])]);
  }), 0)]), _vm._m(12), _c('p', [_vm._v("Enter all the invoice cost values (if applicable).")]), _c('table', {
    staticClass: "table table-striped mb-4"
  }, [_vm._m(13), _c('tbody', _vm._l(_vm.invoiceCostTotals, function (value) {
    return _c('tr', {
      key: value.key
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(value.friendly))]), _c('td', {
      staticClass: "text-info font-w600"
    }, [_c('FormItem', {
      attrs: {
        "id": value.key,
        "type": value.formType,
        "value": _vm.form.values[value.key]
      },
      on: {
        "input": function (input) {
          return _vm.onInputValue(input, value.key);
        }
      }
    })], 1)]);
  }), 0)])]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary btn-block mb-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickSubmit.apply(null, arguments);
      }
    }
  }, [!_vm.loading ? _c('span', [_vm._v("Create Invoice")]) : _c('span', [_c('i', {
    staticClass: "fa fa-spinner fa-spin"
  }), _vm._v(" Creating Invoice...")])])]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.addRate,
      "title": "Add Rate",
      "text": "Please enter/select a rate name",
      "lg-size": ""
    },
    on: {
      "close": function ($event) {
        _vm.modals.addRate = false;
      },
      "submit": _vm.onConfirmRateModal
    }
  }, [_c('AddRateForm', {
    attrs: {
      "register-ids": _vm.filteredRegisterIds,
      "unit-options": _vm.unitOptions
    },
    model: {
      value: _vm.modals.addRate,
      callback: function ($$v) {
        _vm.$set(_vm.modals, "addRate", $$v);
      },
      expression: "modals.addRate"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-3"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Rates")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('i', {
    staticClass: "fa fa-ban mr-1"
  }), _vm._v(" Remove")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Type")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Start Date")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("End Date")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Consumption")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Unit Rate")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Cost")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" The below table are all the totals related to this invoice. "), _c('strong', [_vm._v("Editing a total will not automatically update any related totals (e.g. updating \"Total Tax\" will not update \"Total Cost\"). These values will be used for reporting, so please ensure they are all correct.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Field")]), _c('th', [_vm._v("Current Value")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-3"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Consumption Values")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Field")]), _c('th', [_vm._v("Current Value")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-3"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Costs Values")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Field")]), _c('th', [_vm._v("Current Value")])])]);

}]

export { render, staticRenderFns }